import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import logo from '../static/meraki_logo.png';
import meraki from '../static/meraki_definition.png';

export const Home = () => {
    return (
        <Box
        className="home-component centered-parent"
        height={"100VH"}
        width={"100%"}>
            
            <img id="primary-logo" src={logo} alt="company logo" draggable="false" />
            
            <Typography
            id="primary-title"
            component={"div"}
            marginTop={"-25px"}
            // Help title wrap
            maxWidth={"80%"}
            variant="h6"
            textAlign={"center"}
            fontWeight={"bold"}
            textTransform={"uppercase"}
            letterSpacing={"0.1em"}
            wordSpacing={"0.3em"}>
                Meraki Hospitality Consulting
            </Typography>

            <img id="secondary-logo" src={meraki} alt="company name description" draggable="false" />
        </Box>
    )
}

export default Home;
