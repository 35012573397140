import { Fragment, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { desktopImages, mobileImages } from '../data/aboutImages';
import Footer from '../components/Footer';


export const About = () => {
  const showMobile = window.innerWidth < 1000;

  const [content, setContent] = useState(null);

  const loadImages = (i) => {
    setContent(i);
  };

  useEffect(() => {
    if (showMobile) {
      loadImages(mobileImages);
    } else {
      loadImages(desktopImages);
    }
  }, [content, showMobile]);

  if (content === null) {
    return (
      <Box sx={{
          display: 'flex',
          padding: '3em',
          alignItems: 'center',
          justifyContent: 'center'
      }}>
          <CircularProgress 
          size={'8em'}
          />
      </Box>
    )
  }

  return (
    <Fragment>
    <Box className="about-component centered-parent">
        <Box
        width={"75%"}
        maxWidth={"1000px"}
        maxHeight={"70VH"}>
            <img className="main-images" alt={content[0].alt} src={content[0].image} draggable="false" />
        </Box>
    </Box>
    <Footer />
    </Fragment>
  )
}

export default About;