import conferencePic from '../static/svg/conference.svg';
import trainingPic from '../static/svg/training.svg'
import savingsPic from '../static/svg/savings.svg'
import presentationPic from '../static/svg/presentation.svg'

export const serviceList = [
    {
        "title": "Organizational Change",
        "description": "Environmental scan and assessment to align change efforts with your organization's strategic objectives",
        "image": conferencePic
    },
    {
        "title": "Strategic Planning",
        "description": "Task force minded to fully immerse within your organization to determine challenges, needs, opportunities, and tangible next steps; Uncover insights that drive informed decision-making",
        "image": presentationPic
    },
    {
        "title": "Financial Management",
        "description": "Budget optimization, cash flow management, and evaluating investment opportunities",
        "image": savingsPic
    },
    {
        "title": "Training and Development",
        "description": "Customized interactive and impactful training experiences that meets each individual where they are, fosters enthusiasm, skill acquisition, and application on the job",
        "image": trainingPic
    }
]