import { useState } from 'react';
import { serviceList } from '../data/servicesImages';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMore from '@mui/icons-material/ExpandMore';


const Services = () => {
    const [expanded, setExpanded] = useState(0);
    const [backgroundPhoto, setBackgroundPhoto] = useState(serviceList[0].image);

    const handleServiceChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        setBackgroundPhoto(serviceList[panel].image);
    };

    return (
        <div className="services-component">
            <Box 
            className="centered-parent"
            id="service-page-title">
                <Typography
                borderTop={1}
                borderBottom={1}
                variant='h3'
                sx={{
                    backgroundColor: "#EAEAEA",
                    color: "black",
                    padding: "0 0.5em",
                    transform: "translateY(40%)"
                }}>
                    Services
                </Typography>
            </Box>
            <img id="services-background-photo" src={backgroundPhoto} alt='services depicted' draggable='false'/>
            <Box
            className="centered-parent"
            id="service-options"
            sx={{
                width: "50%",
                textAlign: "center",
            }}>
                {
                    serviceList.map((service, index) => (
                        <Accordion
                        key={index}
                        expanded={expanded === index}
                        onChange={handleServiceChange(index)}
                        sx={{
                            backgroundColor: "#EAEAEA",
                            color: "black",
                        }}>
                            <AccordionSummary
                            expandIcon=<ExpandMore />
                            aria-controls={"panel" + index + "-content"}
                            id={"panel" + index + "-header"}
                            >
                                <Typography variant='h4'>
                                    {service.title}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant='body1'>
                                    {service.description}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))
                }
            </Box>
        </div>
    )
}

export default Services