import { createTheme, ThemeProvider } from '@mui/material/styles';
import primaryTheme from '../themes/primaryTheme';
import Home from '../components/Home';
import Services from '../components/Services';
import About from '../components/About';
import FabButton from '../components/FabButton';
// Behavior
import ScrollToTop from '../behavior/scrollToTop';

const theme = createTheme(primaryTheme(createTheme()));

export default function Main() {
  return (
    <ThemeProvider theme={theme}>
        <ScrollToTop />
        <Home />
        <Services />
        <About />
        <FabButton />
    </ThemeProvider>
  );
}
