const primaryTheme = (theme) => ({
    typography: {
        fontFamily: [
        'Literata',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif'
        ].join(','),
        // Main title (ex. Services)
        h3: {
            fontWeight: '400',            
            [theme.breakpoints.up('xs')]: {
                fontSize: '1.5rem',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '1.4rem',
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '2.4rem',
            }
        },
        // Header (ex. Services)
        h4: {
            width: '100%',
            fontWeight: '400',            
            [theme.breakpoints.up('xs')]: {
                fontSize: '1.0rem',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '1.2rem',
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '1.9rem',
            }
        },
        body1: {    
            [theme.breakpoints.up('xs')]: {
                fontSize: '.70rem',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '0.85rem',
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '1.1rem',
            }
        },
        serviceOptions1: {
            textAlign: "center",
            fontSize: "1.8rem",
            fontWeight: "400",
            color: "#5f093d",
        },
        customFooter: {
            fontSize: "0.65em"
        }
    }
});

export default primaryTheme;