import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

export const Footer = () => {
  return (
    <div className="footer-component">
      <Box
      borderTop={1}
      sx={{
        position: "static",
        height: "2.75em",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        bottom: "1%",
        borderColor: "#5f093d",
        transform: "translateY(-7VH)",
      }}>
        <Typography variant="customFooter">
          Copyright &#169;2023 MerakiHospitalityConsulting;<br></br>
          <Tooltip title="This website is powered for $12/year. Interested? Contact webmaster at swzy0000@gmail.com" enterTouchDelay={0} leaveTouchDelay={3000} placement="top" arrow>
              <Button><Typography variant="customFooter" sx={{transform: "translateY(-8%)"}}>Made with &#9829; by swzy</Typography></Button>
          </Tooltip>
        </Typography>
      </Box>
    </div>
  )
}

export default Footer;