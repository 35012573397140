// Desktop images
import yoe from '../static/meraki-photos-website/yoe.jpg';
// import wed1 from '../static/meraki-photos-website/wed_service.jpg';
// import wed2 from '../static/meraki-photos-website/wed_ex.jpg';
// import outreach1 from '../static/meraki-photos-website/outreach.jpg';
// import outreach2 from '../static/meraki-photos-website/outreach_grp.jpg';
// import final from '../static/meraki-photos-website/final.jpg';
// Mobile images
import yoe_m from '../static/meraki-photos-mobile/yoe.jpg'
// import wed1_m from '../static/meraki-photos-mobile/wed_service.jpg'
// import wed2_m from '../static/meraki-photos-mobile/wed_ex.jpg'
// import outreach1_m from '../static/meraki-photos-mobile/outreach.jpg'
// import outreach2_m from '../static/meraki-photos-mobile/outreach_grp.jpg'
// import final_m from '../static/meraki-photos-mobile/final.jpg'


export const desktopImages = [
    {
      "image": yoe,
      "alt": "Years Of Experience",
    },
    // {
    //   "image": wed1,
    //   "alt": "Wedding Services",
    // },
    // {
    //   "image": wed2,
    //   "alt": "Wedding Example",
    // },
    // {
    //   "image": outreach1,
    //   "alt": "Outreach",
    // },
    // {
    //   "image": outreach2,
    //   "alt": "Everyone as a group",
    // },
    // {
    //   "image": final,
    //   "alt": "Let's Work Together",
    // }
]

export const mobileImages = [
    {
      "image": yoe_m,
      "alt": "Years Of Experience",
    },
    // {
    //   "image": wed1_m,
    //   "alt": "Wedding Services",
    // },
    // {
    //   "image": wed2_m,
    //   "alt": "Wedding Example",
    // },
    // {
    //   "image": outreach1_m,
    //   "alt": "Outreach",
    // },
    // {
    //   "image": outreach2_m,
    //   "alt": "Everyone as a group",
    // },
    // {
    //   "image": final_m,
    //   "alt": "Let's Work Together",
    // }
  ]
