import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

const ErrorPage = () => {
  return (
    <Box
    height={"100VH"}
    width={"100VW"}
    display={"flex"}
    flexDirection={"column"}
    justifyContent={"center"}
    alignItems={"center"}>
        <Typography
        variant="h5">
            Oops!
        </Typography>
        <Typography
        margin={"2em 0"}
        variant="body1">
          There's nothing here. Let's head back home...
        </Typography>

        <Button
        component={Link} to="/">
          <Typography>
            Main Site
          </Typography>
        </Button>
    </Box>
  )
}

export default ErrorPage